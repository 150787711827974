import { Button, CloseIcon, Flex, ModalError, Text, useToast } from '@pancakeswap/uikit'
import { authAPI } from 'apis/authAPI'
import { TYPE_VERIFY_EMAIL } from 'constants/type-verify-email.constant'
import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'

interface ModalProps {
  onDismiss?: () => void
  title: string
  subTitle: string
  subTitle2?: string
}

const BorderBoxCustom = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: grid;
  place-content: center;
  background: #fafafa;
`

const TextCustom = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`

const AlertErrorNetworkModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  onDismiss,
  title,
  subTitle,
  subTitle2,
}) => {
  return (
    <ModalError
      title=""
      onDismiss={() => {
        onDismiss()
      }}
      style={{ position: 'relative' }}
      maxWidth={[327, null, null, 420]}
    >
      <Flex
        width="100%"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{ gap: '0.5rem' }}
      >
        <Flex width="100%" height="auto" justifyContent="center">
          <BorderBoxCustom>
            <ErrorNetworkIcon />
          </BorderBoxCustom>
        </Flex>
        <Text bold fontSize={[20, null, null, 24]} fontWeight={800} marginTop={15}>
          {title}
        </Text>
        <Text width="100%" color="#718096" fontSize="12px" textAlign="center" fontWeight={500}>
          {subTitle} <br />
          {subTitle2}
        </Text>
      </Flex>
    </ModalError>
  )
}

export default AlertErrorNetworkModal

const ErrorNetworkIcon = () => {
  return (
    <svg width="44" height="34" viewBox="0 0 44 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="artwork" clipPath="url(#clip0_2291_1565)">
        <g id="no wifi">
          <g id="Group">
            <g id="Group_2">
              <path
                id="Vector"
                d="M0.580463 10.7386C-0.161742 11.3927 -0.198066 12.5261 0.505395 13.2208L1.31298 14.018C1.95994 14.6566 2.99919 14.6873 3.68328 14.0869C7.6066 10.6438 12.5324 8.28937 17.9745 7.50378C18.418 7.43964 18.7405 7.05521 18.7163 6.61341L18.5613 3.7754C18.5343 3.27664 18.0802 2.90814 17.5789 2.97626C11.0973 3.85547 5.23146 6.63971 0.580463 10.7386Z"
                fill="#FB1919"
              />
              <path
                id="Vector_2"
                d="M43.4195 10.7386C38.7686 6.64013 32.9032 3.85629 26.4227 2.97668C25.921 2.90856 25.4674 3.27745 25.4403 3.77662L25.2874 6.61463C25.2636 7.05603 25.5856 7.44006 26.0288 7.5042C31.4712 8.29059 36.397 10.6462 40.3204 14.0905C41.0024 14.6893 42.0409 14.6554 42.6862 14.018L43.4942 13.2205C44.1977 12.5261 44.1613 11.3923 43.4191 10.7382L43.4195 10.7386Z"
                fill="#FB1919"
              />
              <path
                id="Vector_3"
                d="M7.12205 17.1476C6.35361 17.7926 6.308 18.9483 7.02115 19.6522L7.82955 20.4506C8.4652 21.078 9.48024 21.1182 10.168 20.547C12.5197 18.5929 15.3747 17.2106 18.5138 16.6162C18.938 16.5357 19.2371 16.1589 19.2141 15.733L19.0587 12.8822C19.0308 12.3739 18.5611 12.0002 18.0529 12.0871C13.9286 12.7918 10.1809 14.5817 7.12245 17.148L7.12205 17.1476Z"
                fill="#FB1919"
              />
              <path
                id="Vector_4"
                d="M36.8705 17.1408C33.8161 14.5797 30.074 12.793 25.9562 12.0879C25.4476 12.0006 24.9775 12.3751 24.95 12.8838L24.7967 15.735C24.7736 16.1604 25.0723 16.5369 25.4965 16.6174C28.6312 17.2129 31.483 18.5945 33.8319 20.5461C34.5196 21.1174 35.5346 21.0776 36.1699 20.4501L36.9791 19.6514C37.695 18.9447 37.6426 17.7874 36.8709 17.1404L36.8705 17.1408Z"
                fill="#FB1919"
              />
              <path
                id="Vector_5"
                d="M13.7444 23.5343C12.9025 24.1566 12.8153 25.3712 13.5591 26.1054L14.376 26.9121C14.9757 27.5045 15.9226 27.5778 16.6099 27.0862C17.3682 26.5437 18.2142 26.1126 19.1222 25.8174C19.494 25.6967 19.7385 25.3465 19.7175 24.9609L19.5589 22.0548C19.5295 21.517 19.008 21.1377 18.4805 21.2748C16.7443 21.7257 15.142 22.5022 13.7448 23.5347L13.7444 23.5343Z"
                fill="#FB1919"
              />
              <path
                id="Vector_6"
                d="M30.2558 23.5347C28.8626 22.5053 27.2655 21.7305 25.5353 21.2787C25.0075 21.1409 24.4852 21.5209 24.4561 22.0591L24.2996 24.9669C24.279 25.3517 24.5227 25.7015 24.8936 25.8226C25.7957 26.1174 26.6359 26.5464 27.3895 27.0858C28.0764 27.5774 29.0232 27.5049 29.6233 26.9125L30.4406 26.1058C31.1844 25.3716 31.0977 24.1566 30.2558 23.5343V23.5347Z"
                fill="#FB1919"
              />
              <path
                id="Vector_7"
                d="M21.9998 34C23.6002 34 24.8976 32.7194 24.8976 31.1397C24.8976 29.56 23.6002 28.2794 21.9998 28.2794C20.3994 28.2794 19.1021 29.56 19.1021 31.1397C19.1021 32.7194 20.3994 34 21.9998 34Z"
                fill="#FB1919"
              />
            </g>
            <path
              id="Vector_8"
              d="M21.8078 0.00402799C20.3972 0.0833044 19.3156 1.34017 19.3915 2.81097L20.4933 24.1443V24.153C20.5356 24.9183 21.1241 25.5438 21.8752 25.5856C22.6811 25.6302 23.3693 24.9852 23.412 24.1443L24.4989 2.81097V2.80579C24.5038 2.71297 24.5038 2.61895 24.4989 2.52374C24.423 1.05255 23.2179 -0.0756468 21.8078 0.00362962V0.00402799Z"
              fill="#FB1919"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2291_1565">
          <rect width="44" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
