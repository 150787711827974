import axios from 'axios'

export const userApi = {
  async updateProfile(data: { walletAddress: string }) {
    return axios.patch('users', data)
  },

  async changePassword(data: { oldPassword: string; newPassword: string; confirmNewPassword: string }) {
    return axios.post('auth/reset-password', data)
  },

  async updatePhonNumber(phone: string) {
    return axios.patch('users/phone-numbers', { phoneNo: phone })
  },
}
