import { Button, Flex, ModalError, Text } from '@pancakeswap/uikit'
import { useRouter } from 'next/router'
import React from 'react'

interface ModalProps {
  onDismiss?: () => void
  title: string
  subTitle: string
  subTitle2?: string
}

const AlertChangeWalletModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  onDismiss,
  title,
  subTitle,
  subTitle2,
}) => {
  const { push } = useRouter()

  return (
    <ModalError title="" style={{ position: 'relative' }} maxWidth={[327, null, null, 420]}>
      <Flex
        width="100%"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{ gap: '0.5rem' }}
      >
        <Flex width="100%" height="auto" justifyContent="center">
          <ErrorChangeWalletIcon />
        </Flex>
        <Text bold fontSize={[20, null, null, 24]} fontWeight={800} marginTop={15}>
          {title}
        </Text>
        <Text width="100%" color="#718096" fontSize="12px" textAlign="center" fontWeight={500}>
          {subTitle} <br />
          {subTitle2}
        </Text>
        <Flex width="100%" justifyContent="center" marginTop={30}>
          <Button
            width="100%"
            onClick={() => {
              push('/wallet')
              onDismiss?.()
            }}
          >
            <Text fontSize={[14]} fontWeight={800} color="#ffffff">
              Chỉnh sửa địa chỉ ví
            </Text>
          </Button>
        </Flex>
      </Flex>
    </ModalError>
  )
}

export default AlertChangeWalletModal

const ErrorChangeWalletIcon = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon">
        <rect width="80" height="80" rx="40" fill="#FAFAFA" />
        <g id="vuesax/bold/wallet-remove">
          <g id="wallet-remove">
            <path
              id="Vector"
              d="M55.8335 40.833H51.6668C49.8335 40.833 48.3335 42.333 48.3335 44.1663C48.3335 45.9997 49.8335 47.4997 51.6668 47.4997H55.8335C56.3002 47.4997 56.6668 47.133 56.6668 46.6663V41.6663C56.6668 41.1997 56.3002 40.833 55.8335 40.833Z"
              fill="#FF784B"
            />
            <path
              id="Vector_2"
              d="M28.3332 45C26.3332 45 24.5498 45.8833 23.3332 47.2667C22.2998 48.45 21.6665 49.9833 21.6665 51.6667C21.6665 52.9167 22.0165 54.1 22.6332 55.1C23.7832 57.0333 25.8998 58.3333 28.3332 58.3333C30.0165 58.3333 31.5498 57.7167 32.7165 56.6667C33.2332 56.2333 33.6832 55.7 34.0332 55.1C34.6498 54.1 34.9998 52.9167 34.9998 51.6667C34.9998 47.9833 32.0165 45 28.3332 45ZM30.9998 54.3C30.7498 54.55 30.4332 54.6667 30.1165 54.6667C29.7998 54.6667 29.4832 54.55 29.2332 54.3L28.3498 53.4167L27.4332 54.3333C27.1832 54.5833 26.8665 54.7 26.5498 54.7C26.2332 54.7 25.9165 54.5833 25.6665 54.3333C25.1832 53.85 25.1832 53.05 25.6665 52.5667L26.5832 51.65L25.6998 50.7667C25.2165 50.2833 25.2165 49.4833 25.6998 49C26.1832 48.5167 26.9832 48.5167 27.4665 49L28.3498 49.8833L29.1832 49.05C29.6665 48.5667 30.4665 48.5667 30.9498 49.05C31.4332 49.5333 31.4332 50.3333 30.9498 50.8167L30.1165 51.65L30.9998 52.5333C31.4832 53.0167 31.4832 53.8 30.9998 54.3Z"
              fill="#FF784B"
            />
            <path
              id="Vector_3"
              d="M47.5505 29.0006C48.0505 29.4839 47.6338 30.2339 46.9338 30.2339L33.1338 30.2173C32.3338 30.2173 31.9338 29.2506 32.5005 28.6839L35.4172 25.7506C37.8838 23.3006 41.8672 23.3006 44.3338 25.7506L47.4838 28.9339C47.5005 28.9506 47.5338 28.9839 47.5505 29.0006Z"
              fill="#FF784B"
            />
            <path
              id="Vector_4"
              d="M56.4499 51.1003C55.4333 54.5337 52.4999 56.667 48.4999 56.667H37.6666C37.0166 56.667 36.5999 55.9503 36.8666 55.3503C37.3666 54.1837 37.6833 52.867 37.6833 51.667C37.6833 46.617 33.5666 42.5003 28.5166 42.5003C27.2499 42.5003 26.0166 42.767 24.8833 43.267C24.2666 43.5337 23.5166 43.117 23.5166 42.4503V40.0003C23.5166 35.467 26.2499 32.3003 30.4999 31.767C30.9166 31.7003 31.3666 31.667 31.8333 31.667H48.4999C48.9333 31.667 49.3499 31.6837 49.7499 31.7503C53.1166 32.1337 55.5499 34.1837 56.4499 37.2337C56.6166 37.7837 56.2166 38.3337 55.6499 38.3337H51.8333C48.2166 38.3337 45.3499 41.6337 46.1333 45.3837C46.6833 48.117 49.2166 50.0003 51.9999 50.0003H55.6499C56.2333 50.0003 56.6166 50.567 56.4499 51.1003Z"
              fill="#FF784B"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
