export enum PAGE {
		HOME = `/`,
		REGISTER = `/register`,
		SIGN_IN = `/signin`,
		PROFILE = `/profile`,
		NOT_FOUND = `/404`,
		INTERNAL_SERVER_ERROR = `/500`,
		QR_CODE = `/qr-code`,
		FORGOT_PASSWORD = `/forgot-password`,
		VERIFY_OTP_RESET_PASSWORD = `/forgot-password/verify-otp-reset-password`,
	WALLET = `/wallet`,
}