import React, { useContext, useEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { Text } from "../../../../components/Text";
import { Box, Flex } from "../../../../components/Box";
import { ChevronDownIcon } from "../../../../components/Svg";
import { UserMenuProps, variants } from "../UserMenu/types";
import { Avatar, UserMenuItem } from "../UserMenu/styles";
import { LoginContext } from "../../../../../../../src/contexts/LoginContext";

export const StyledUserMenu = styled(Flex)<{ isShow?: boolean }>`
  align-items: center;
  background-color: #fafafa;
  border-radius: 90px;
  cursor: pointer;
  display: inline-flex;
  height: 46px;
  width: auto;
  margin-left: 8px;
  padding-left: 8px;
  padding-right: 4px;
  position: relative;

  &:hover {
    opacity: 0.65;
  }
  @media screen and (max-width: 600px) {
    height: 42px;
  }
`;

export const LabelText = styled.div<{ isShowText?: boolean }>`
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
  display: ${({ isShowText }) => (!isShowText ? "block" : "none")};
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const Menu = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.card.background};
  border-radius: 16px;
  padding-bottom: 4px;
  padding-top: 4px;
  pointer-events: auto;
  width: 280px;
  visibility: visible;
  z-index: 1001;
  box-shadow: 12px 4px 32px rgba(93, 106, 131, 0.1);

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
  ${UserMenuItem} {
    border-radius: 8px;
  }
`;

const ProfileUikitMenu: React.FC<UserMenuProps> = ({
  children,
  disabled,
  placement = "bottom-end",
  isShowIcon = !false,
  ...props
}) => {
  const { user } = useContext(LoginContext);
  const [isOpen, setIsOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: "fixed",
    placement,
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
  });

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false);
        evt.stopPropagation();
      }
    };

    targetRef?.addEventListener("mouseenter", showDropdownMenu);
    targetRef?.addEventListener("mouseleave", hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener("mouseenter", showDropdownMenu);
      targetRef?.removeEventListener("mouseleave", hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen]);

  return (
    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu
        onTouchStart={() => {
          setIsOpen((s) => !s);
        }}
        isShow={isShowIcon}
      >
        <Flex alignItems="center" justifyContent="space-around" width="100%">
          <LabelText>{user?.email}</LabelText>
          {!disabled && <ChevronDownIcon color="text" width="24px" />}
        </Flex>
      </StyledUserMenu>
      {!disabled && (
        <Menu style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
          <Box onClick={() => setIsOpen(false)}>{children?.({ isOpen })}</Box>
        </Menu>
      )}
    </Flex>
  );
};

export default ProfileUikitMenu;
