import { createReducer } from '@reduxjs/toolkit'
import { fetchActiveTab } from './actions'

interface global {
  isDepositToWallet: boolean
}
export const initialState: global = {
  isDepositToWallet: !false,
}
export default createReducer(initialState, (builder) =>
  builder.addCase(fetchActiveTab, (state, action) => {
    state.isDepositToWallet = action.payload.isDepositToWallet
  }),
)
