import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 25" {...props} style={{ fill: "none" }}>
      <rect width="24" height="24" rx="12" fill="#22C55E" />
      <path
        d="M11.9998 6.16675C8.78567 6.16675 6.1665 8.78591 6.1665 12.0001C6.1665 15.2142 8.78567 17.8334 11.9998 17.8334C15.214 17.8334 17.8332 15.2142 17.8332 12.0001C17.8332 8.78591 15.214 6.16675 11.9998 6.16675ZM14.7882 10.6584L11.4807 13.9659C11.399 14.0476 11.2882 14.0942 11.1715 14.0942C11.0548 14.0942 10.944 14.0476 10.8623 13.9659L9.2115 12.3151C9.04234 12.1459 9.04234 11.8659 9.2115 11.6967C9.38067 11.5276 9.66067 11.5276 9.82984 11.6967L11.1715 13.0384L14.1698 10.0401C14.339 9.87092 14.619 9.87092 14.7882 10.0401C14.9573 10.2092 14.9573 10.4834 14.7882 10.6584Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
