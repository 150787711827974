import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#FB1919" />
      <path
        d="M11.9998 6.16675C8.78567 6.16675 6.1665 8.78591 6.1665 12.0001C6.1665 15.2142 8.78567 17.8334 11.9998 17.8334C15.214 17.8334 17.8332 15.2142 17.8332 12.0001C17.8332 8.78591 15.214 6.16675 11.9998 6.16675ZM13.9598 13.3417C14.129 13.5109 14.129 13.7909 13.9598 13.9601C13.8723 14.0476 13.7615 14.0884 13.6507 14.0884C13.5398 14.0884 13.429 14.0476 13.3415 13.9601L11.9998 12.6184L10.6582 13.9601C10.5707 14.0476 10.4598 14.0884 10.349 14.0884C10.2382 14.0884 10.1273 14.0476 10.0398 13.9601C9.87067 13.7909 9.87067 13.5109 10.0398 13.3417L11.3815 12.0001L10.0398 10.6584C9.87067 10.4892 9.87067 10.2092 10.0398 10.0401C10.209 9.87092 10.489 9.87092 10.6582 10.0401L11.9998 11.3817L13.3415 10.0401C13.5107 9.87092 13.7907 9.87092 13.9598 10.0401C14.129 10.2092 14.129 10.4892 13.9598 10.6584L12.6182 12.0001L13.9598 13.3417Z"
        fill="white"
      />
    </svg>
  );
};

export default Icon;
