import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6" stroke="#194BFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M17 13.4H13.8C11.4 13.4 10.6 12.6 10.6 10.2V7L17 13.4Z" stroke="#194BFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M11.6 2H15.6" stroke="#194BFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M7 5C7 3.34 8.34 2 10 2H12.62" stroke="#194BFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M21.9999 8V14.19C21.9999 15.74 20.7399 17 19.1899 17" stroke="#194BFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z" stroke="#194BFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
