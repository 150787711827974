import { ContextApi } from '@pancakeswap/localization'
import {
  EarnFillIcon,
  EarnIcon, MenuItemsType, ExChangeFillIcon, ExChangeIcon, MarketplaceIcon, MarketplaceFillIcon, AccountFilledIcon, AccountIcon,PartnerFillIcon, PartnerIcon
  
} from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_BSC, SUPPORT_ZAP } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) => []
  // [
  //   {
  //     label: t('Marketplace'),
  //     icon: MarketplaceIcon,
  //     fillIcon: MarketplaceFillIcon,
  //     href: '/marketplace',
  //     showItemsOnMobile: false,
  //     items:
  //      [
  //       {
  //         label: t('Marketplace'),
  //         href: '/marketplace',
  //         supportChainIds:[ChainId.BSC]
  //       },
  //       // {
  //       //   label: t('Mystery Box'),
  //       //   href: '/mysterybox',
  //       //   supportChainIds:[ChainId.ONUS, ChainId.ONUS_TESTNET, ChainId.ETHW_MAINNET]
  //       // },
  //     ].map((item) => addMenuItemSupported(item, chainId))
  //   },
  //   {
  //     label: t('Account'),
  //     icon: AccountIcon,
  //     fillIcon: AccountFilledIcon,
  //     href: '/account',
  //     items: [
  //       {
  //         label: t('My Balance'),
  //         href: '/mybalance',
  //         supportChainIds:[ChainId.BSC, ChainId.BSC_TESTNET, ChainId.ONUS, ChainId.ETHW_MAINNET]
  //       },
  //       {
  //         label: t('Inventory'),
  //         href: '/inventory',
  //         // supportChainIds: [ChainId.BSC, ChainId.BSC_TESTNET, ChainId.ETHW_MAINNET, ChainId.ONUS]
  //         supportChainIds: [ChainId.BSC, ChainId.BSC_TESTNET]
  //       },
  //       {
  //         label: t('Account'),
  //         href: '/account',
  //       },
  //     ].map((item) => addMenuItemSupported(item, chainId)),
  //   },
  //   {
  //     label: t('Earn'),
  //     icon: EarnIcon,
  //     fillIcon: EarnFillIcon,
  //     href: '/farms',
  //     items: [
  //       {
  //         label: t('Invest Together'),
  //         href: '/investtogether',
  //         supportChainIds: [ChainId.BSC, ChainId.BSC_TESTNET],
  //       },
  //       {
  //         label: t('Pawn NFT'),
  //         href: 'https://dapp.bami.money/pawn',
  //         type:1
  //       },
  //       {
  //         label: t('Pools'),
  //         href: '/pools',
  //         supportChainIds: [ChainId.BSC, ChainId.BSC_TESTNET, ChainId.ONUS_TESTNET],
  //       },
  //       {
  //         label: t('Farms'),
  //         href: '/farms',
  //         supportChainIds: [ChainId.BSC, ChainId.ONUS, ChainId.ONUS_TESTNET],
  //       },
  //     ].map((item) => addMenuItemSupported(item, chainId)),
  //   },
  //   {
  //     label: t('Partners'),
  //     icon: PartnerIcon,
  //     fillIcon: PartnerFillIcon,
  //     href: '/mypartner',
  //     showItemsOnMobile: false,
  //     items: [
  //       {
  //         label: t('Partners'),
  //         href: '/mypartner',
  //       },
  //     ].map((item) => addMenuItemSupported(item, chainId)),
  //   },
  //   {
  //     label: t('Exchange'),
  //     href: '/swap',
  //     icon: ExChangeIcon,
  //     fillIcon: ExChangeFillIcon,
  //     items: [
  //       {
  //         label: t('Swap'),
  //         href: '/swap',
  //       },
  //       {
  //         label: t('Liquidity'),
  //         href: '/liquidity',
  //       },
  //     ].map((item) => addMenuItemSupported(item, chainId)),
  //   },
  // ].map((item) => addMenuItemSupported(item, chainId))

export default config
