import axios from 'axios'
import { API_URL } from '../constants/api-url.constants'

const me = async () => (await axios.get(API_URL.ME)).data

const login = async (payload: { email: string; password: string }) => {
  return (await axios.post(API_URL.LOGIN, payload)).data
}

const forgotPasswordRequest = async (payload: { email: string }) =>
  (await axios.post(API_URL.FORGOT_PASSWORD_REQUEST, payload)).data
const forgotPassword = async (payload: { code: string; newPassword: string; confirmNewPassword: string }) =>
  (await axios.post(API_URL.FORGOT_PASSWORD, payload)).data
const register = (payload) =>
  axios({
    method: 'POST',
    url: '/auth/registers',
    data: payload,
  })

const googleLogin = async (payload: { accessToken: string }) => (await axios.post(API_URL.GOOGLE_LOGIN, payload)).data
const verification = (payload) => {
  return axios({
    method: 'POST',
    url: '/auth/email-verification',
    data: payload,
  })
}

const resendEmail = (payload) =>
  axios({
    method: 'POST',
    url: '/auth/resend-code-verification',
    data: payload,
  })

export const authAPI = {
  me,
  login,
  googleLogin,
  forgotPasswordRequest,
  forgotPassword,
  register,
  verification,
  resendEmail,
}
