import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M13 10.9998L21.2 2.7998" stroke="#194BFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M22 6.8V2H17.2" stroke="#194BFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="#194BFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
