import React, { createContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useAccount } from 'wagmi'
import { userApi } from 'apis/user.api'
import { PAGE } from '../constants/page.constants'
import { KEY } from '../constants/key.constants'
import { authAPI } from '../apis/authAPI'
import { jwtManager } from '../helper/jwt'
import configAxios from '../apis/axiosconfig'

export interface IUserBank {
  accName: string
  accNo: string
  accType: string
  bankNo: string
  bankName: string
  id: number
}

export interface IUser {
  userId: string
  email: string
  provider: string
  walletAddresses?: string[] // Deprecated
  walletAddress?: string
  banks: IUserBank[]
}
interface LoginContextProps {
  isLoggedIn: boolean
  login: () => void
  logout: () => void
  refreshUser: () => void
  user?: IUser | null
}

export const LoginContext = createContext<LoginContextProps>({
  isLoggedIn: false,
  login: () => undefined,
  logout: () => undefined,
  refreshUser: () => undefined,
  user: null,
})

export const LoginProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [user, setUser] = useState<null | IUser>(null) // [1
  const router = useRouter()
  const account = useAccount()

  const login = () => {
    configAxios()
    authAPI
      .me()
      .then(({ data }) => {
        setUser(data)
        setIsLoggedIn(true)
        router.push(PAGE.HOME)
      })
      .catch((err) => {
        console.log(err)
        setIsLoggedIn(false)
      })
  }

  const logout = () => {
    setIsLoggedIn(false)
    jwtManager.clear()
    router.push('/signin')
  }

  const refreshUser = () => {
    authAPI.me().then(({ data }) => {
      setUser(data)
    })
  }

  useEffect(() => {
    const token = localStorage.getItem(KEY.TOKEN)
    if (token) {
      authAPI
        .me()
        .then(({ data }) => {
          setUser(data)
          setIsLoggedIn(true)
        })
        .catch((err) => {
          console.log(err)
          setIsLoggedIn(false)
        })
    }
  }, [])

  useEffect(() => {
    if (user && account && !user.walletAddress)
      userApi
        .updateProfile({
          walletAddress: account.address,
        })
        .then(() => {
          router.reload()
        })
        .catch((err) => {
          console.log(err)
        })
  }, [account, user])

  return (
    <LoginContext.Provider value={{ isLoggedIn, login, logout, user, refreshUser }}>{children}</LoginContext.Provider>
  )
}
