import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { Box, Flex } from "../../../../components/Box";
import { ChevronDownIcon } from "../../../../components/Svg";
import MenuIcon from "./MenuIcon";
import { UserMenuItem } from "./styles";
import { UserMenuProps, variants } from "./types";

export const StyledUserMenu = styled(Flex)<{ isShow?: boolean; isHidenArrow?: boolean }>`
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  display: inline-flex;
  height: 46px;
  padding-left: ${({ isShow }) => (isShow ? "32px" : "8px")};
  padding-right: ${({ isHidenArrow }) => (isHidenArrow ? "8px" : "12px")};
  position: relative;
  &:hover {
    opacity: 0.65;
  }
  @media screen and (max-width: 600px) {
    height: 42px;
  }
  @media screen and (max-width: 425px) {
    border-radius: 8px;
  }
`;

export const LabelText = styled.div<{ isShowText?: boolean }>`
  font-weight: 800;
  display: ${({ isShowText }) => (!isShowText ? "block" : "none")};
  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
    margin-left: 8px;
    margin-right: 4px;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const Menu = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.card.background};
  border-radius: 16px;
  pointer-events: auto;
  width: 280px;
  visibility: visible;
  z-index: 1001;
  position: absolute;
  top: 80px;
  left: -35px;
  box-shadow: 4px 12px 32px rgba(93, 106, 131, 0.1);

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
  ${UserMenuItem} {
    border-radius: 8px;
  }

  @media screen and (max-width: 768px) {
    left: -85px;
  }
  @media screen and (max-width: 575px) {
    left: -120px;
  }
`;

const UserMenu: React.FC<UserMenuProps> = ({
  account,
  text,
  avatarSrc,
  variant = variants.DEFAULT,
  children,
  disabled,
  placement = "bottom-end",
  isShowIcon = !false,
  color,
  backgroundColor,
  hideArrow,
  ...props
}) => {
  const { pathname } = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const [accountEllipsis, setAccountEllipsis] = useState<string | null>(null);
  useEffect(() => {
    if (window.screen.width < 321) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const accountEllipsis = account ? `${account.substring(0, 2)}...${account.substring(account.length - 2)}` : null;
      setAccountEllipsis(accountEllipsis);
    }
    if (window.screen.width > 322) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null;
      setAccountEllipsis(accountEllipsis);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.screen.width, account]);
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: "fixed",
    placement,
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
  });

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false);
        evt.stopPropagation();
      }
    };

    targetRef?.addEventListener("mouseenter", showDropdownMenu);
    targetRef?.addEventListener("mouseleave", hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener("mouseenter", showDropdownMenu);
      targetRef?.removeEventListener("mouseleave", hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen]);

  return (
    <Flex alignItems="center" height="100%" position="relative" ref={setTargetRef} {...props}>
      <StyledUserMenu
        onTouchStart={() => {
          setIsOpen((s) => !s);
        }}
        isShow={isShowIcon}
        backgroundColor={backgroundColor || "#194BFB"}
        color={color || "#FFFFFF"}
        paddingRight="20px"
      >
        {isShowIcon && <MenuIcon avatarSrc={avatarSrc} variant={variant} />}
        <LabelText isShowText={isShowIcon} title={typeof text === "string" ? text || account : account}>
          {text || accountEllipsis}
        </LabelText>
        {!disabled && !hideArrow && (
          <ChevronDownIcon
            color={
              pathname === "/forgot-password" ||
              pathname === "/forgot-password/verify-otp-reset-password" ||
              pathname === "/verify-email"
                ? "#ffffff"
                : "text"
            }
            width="24px"
          />
        )}
      </StyledUserMenu>
      {!disabled && (
        <Menu ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
          <Box onClick={() => setIsOpen(false)}>{children?.({ isOpen })}</Box>
        </Menu>
      )}
    </Flex>
  );
};

export default UserMenu;
