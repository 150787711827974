import { createReducer } from '@reduxjs/toolkit'
import { fetchRefresh } from './actions'

interface global {
  isRefresh: any
}
export const initialState: global = {
  isRefresh: 0,
}

export default createReducer(initialState, (builder) =>
  builder.addCase(fetchRefresh, (state, action) => {
    state.isRefresh = action.payload.isRefresh
  }),
)
