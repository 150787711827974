import { useRouter } from "next/router";
import React from "react";
import styled, { DefaultTheme } from "styled-components";
import Flex from "../Box/Flex";
import { IconButton } from "../Button";
import BlockIcon from "../Svg/Icons/Block";
import CheckmarkCircleIcon from "../Svg/Icons/CheckmarkCircle";
import ErrorIcon from "../Svg/Icons/Error";
import InfoIcon from "../Svg/Icons/Info";
import { Text } from "../Text";
import { AlertProps, variants } from "./types";

interface ThemedIconLabel {
  variant: AlertProps["variant"];
  theme: DefaultTheme;
  hasDescription: boolean;
}

const getThemeColor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.DANGER:
      return theme.colors.failure;
    case variants.WARNING:
      return theme.colors.warning;
    case variants.SUCCESS:
      return theme.colors.success;
    case variants.INFO:
    default:
      return theme.colors.secondary;
  }
};

const getBackgroundColor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.DANGER:
      return "#FAEDED";
    case variants.WARNING:
      return "#FAEDED";
    case variants.SUCCESS:
      return "#EDF7F1";
    case variants.INFO:
    default:
      return theme.colors.secondary;
  }
};

const getBorderColor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.DANGER:
      return "#FB1919";
    case variants.WARNING:
      return "#FB1919";
    case variants.SUCCESS:
      return "#22C55E";
    case variants.INFO:
    default:
      return theme.colors.secondary;
  }
};

const getIcon = (variant: AlertProps["variant"] = variants.INFO) => {
  switch (variant) {
    case variants.DANGER:
      return BlockIcon;
    case variants.WARNING:
      return ErrorIcon;
    case variants.SUCCESS:
      return CheckmarkCircleIcon;
    case variants.INFO:
    default:
      return InfoIcon;
  }
};

const withHandlerSpacing = 32 + 12 + 8; // button size + inner spacing + handler position
const Details = styled.div<{ hasHandler: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 12px;
  padding-right: ${({ hasHandler }) => (hasHandler ? `${withHandlerSpacing}px` : "12px")};
`;

const CloseHandler = styled.div`
  border-radius: 0 16px 16px 0;
  right: 8px;
  position: absolute;
  top: 8px;
`;

const StyledAlert = styled(Flex)<ThemedIconLabel>`
  position: relative;
  padding: 10px;
  background-color: ${getBackgroundColor};
  border: 1px solid ${getBorderColor};
  border-radius: 12px;
`;

const Alert: React.FC<React.PropsWithChildren<AlertProps>> = ({ title, children, variant, onClick }) => {
  const Icon = getIcon(variant);
  const { push } = useRouter();

  return (
    <StyledAlert variant={variant} hasDescription={!!children}>
      <Icon width="24px" />
      <Details hasHandler={!!onClick}>
        <Text fontSize="12px" fontWeight="700" color="#1A202C">
          {title}
        </Text>
        {typeof children === "string" ? (
          <>
            <Text fontSize="12px" color="#718096">
              {children}
            </Text>
            {children.toString().search("TX") > -1 && (
              <Text
                fontWeight={800}
                fontSize={12}
                color={variant === variants.SUCCESS ? "#22C55E" : variant === variants.DANGER ? "#FB1919" : "#FB1919"}
                onClick={() => push({ pathname: "/transaction", query: { tx: children.toString() } })}
                style={{ cursor: "pointer" }}
              >
                Xem chi tiết giao dịch
              </Text>
            )}
          </>
        ) : (
          children
        )}
      </Details>
      {onClick && (
        <CloseHandler>
          <IconButton scale="sm" variant="text" onClick={onClick}>
            <CloseIcon />
          </IconButton>
        </CloseHandler>
      )}
    </StyledAlert>
  );
};

export default Alert;

const CloseIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2998 4.7002L4.70014 11.2999" stroke="#1A202C" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.7002 4.7002L11.2999 11.2999" stroke="#1A202C" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
