/* eslint-disable @next/next/no-sync-scripts */
/* eslint-disable @next/next/no-page-custom-font */

import { ResetCSS, ToastListener, useModal } from '@pancakeswap/uikit'
import configAxios from 'apis/axiosconfig'
import BigNumber from 'bignumber.js'
import { NetworkModal } from 'components/NetworkModal'
import { FixedSubgraphHealthIndicator } from 'components/SubgraphHealthIndicator/FixedSubgraphHealthIndicator'
import TransactionsDetailModal from 'components/TransactionDetailModal'
import { LoginContext } from 'contexts/LoginContext'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useEagerConnect from 'hooks/useEagerConnect'
import useEagerConnectMP from 'hooks/useEagerConnect.bmp'
import useThemeCookie from 'hooks/useThemeCookie'
import useUserAgent from 'hooks/useUserAgent'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { Fragment, useContext, useEffect, useState } from 'react'
import OneSignal from 'react-onesignal'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import AlertChangeWalletModal from 'views/Signin/components/AlertChangeWalletModal'
import AlertErrorNetworkModal from 'views/Signin/components/AlertErrorNetworkModal'
import { Blocklist, Updaters } from '..'
import Providers from '../Providers'
import { SentryErrorBoundary } from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import GlobalStyle from '../style/Global'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })
// console.log('usePollCoreFarmData', usePollCoreFarmData);

// This config is required for number formatting
BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
})

function GlobalHooks() {
    usePollBlockNumber()
    useEagerConnect()
    usePollCoreFarmData()
    useUserAgent()
    useAccountEventListener()
    useThemeCookie()
    return null
}

function MPGlobalHooks() {
    usePollBlockNumber()
    useEagerConnectMP()
    usePollCoreFarmData()
    useUserAgent()
    useAccountEventListener()
    return null
}

configAxios()

function MyApp(props: AppProps<{ initialReduxState: any }>) {
    const { pageProps, Component } = props
    const store = useStore(pageProps.initialReduxState)

    useEffect(() => {
        OneSignal.init({
            appId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID,
            allowLocalhostAsSecureOrigin: true,
            serviceWorkerParam: { scope: '/' },
            serviceWorkerPath: 'OneSignalSDKWorker.js',
            serviceWorkerUpdaterPath: 'OneSignalSDKUpdaterWorker.js',
        }).then(() => {
            OneSignal.showNativePrompt().then()
            OneSignal.registerForPushNotifications().then(() => {
                console.log(`registerForPushNotifications`)
            })
            OneSignal.getNotificationPermission().then((permission) => {
                console.log('permission', permission)
            })
        })
    }, [])
    return (
        <>
            <Head>
                <meta name="description" content="dePay" />
                <meta name="theme-color" content="#1FC7D4" />
                <meta name="twitter:image" content="/images/depay-logo.jpg" />
                <meta name="twitter:description" content="dePay" />
                <meta name="keywords" content="dePay" />
                <meta name="robots" content="index, follow" />
                <meta name="googlebot" content="index, follow" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="dePay" />
                <meta name="google-site-verification" content="q_rBnGVdfFXZhhPcnYrhf5TOXt4QRvXkANC8fVC66Eo" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <meta property="og:image" content="/images/depay-logo.jpg" />
                <meta property="og:url" content="https://depay.ai/" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-BJPRZK4Y8N" />
                <title>dePay</title>
                {(Component as NextPageWithLayout).mp && (
                    // eslint-disable-next-line @next/next/no-sync-scripts
                    <script src="https://public.bnbstatic.com/static/js/mp-webview-sdk/webview-v1.0.0.min.js" id="mp-webview" />
                )}
            </Head>
            <Providers store={store}>
                <Blocklist>
                    {(Component as NextPageWithLayout).mp ? <MPGlobalHooks /> : <GlobalHooks />}
                    <ResetCSS />
                    <GlobalStyle />
                    <PersistGate loading={null} persistor={persistor}>
                        <Updaters />
                        <App {...props} />
                    </PersistGate>
                </Blocklist>
            </Providers>

            <Script
                strategy="afterInteractive"
                id="google-tag"
                dangerouslySetInnerHTML={{
                    __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTAG}');
            `,
                }}
            />

            <Script
                id="google-analytics"
                dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-BJPRZK4Y8N');
                        `,
                }}
            />
        </>
    )
}

type NextPageWithLayout = NextPage & {
    Layout?: React.FC<React.PropsWithChildren<unknown>>
    /** render component without all layouts */
    pure?: true
    /** is mini program */
    mp?: boolean
    /**
     * allow chain per page, empty array bypass chain block modal
     * @default [ChainId.BSC]
     * */
    chains?: number[]
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? SentryErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
    const { account } = useActiveWeb3React()
    const { user }: any = useContext(LoginContext)
    const { pathname } = useRouter()
    const [networkError, onDismiss] = useModal(
        <AlertErrorNetworkModal title="Bị lỗi mạng" subTitle="Vui lòng kiểm tra kết nối của bạn và thử lại sau." />,
    )
    const [isConnectNetwork, setIsConnectNetwork] = useState(false)
    const [openWrongWalletModal, onDismissErrorModal] = useModal(
        <AlertChangeWalletModal
            title="Thông báo"
            subTitle="Địa chỉ ví người dùng hiện tại không tồn tai."
            subTitle2="Vui lòng kết nối địa chỉ ví của ban."
        />,
    )

    useEffect(() => {
        if (!isConnectNetwork && !navigator.onLine) {
            networkError()
        } else {
            onDismiss()
        }
    }, [isConnectNetwork])

    useEffect(() => {
        if (user && account) {
            const isWrongWallet = user.walletAddress && user.walletAddress !== account
            if (isWrongWallet) {
                openWrongWalletModal()
            } else {
                onDismissErrorModal()
            }
        }
    }, [account, user])

    useEffect(() => {
        const handleOnline = () => {
            setIsConnectNetwork(true)
        }
        const handleOffline = () => {
            setIsConnectNetwork(false)
        }
        if (navigator.onLine) {
            handleOnline()
        } else {
            handleOffline()
        }
        window.addEventListener('online', handleOnline)
        window.addEventListener('offline', handleOffline)
        return () => {
            window.removeEventListener('online', handleOnline)
            window.removeEventListener('offline', handleOffline)
        }
    }, [])

    if (Component.pure) {
        return <Component {...pageProps} />
    }

    // Use the layout defined at the page level, if available
    const Layout = Component.Layout || Fragment
    const ShowMenu = Component.mp ? Fragment : Menu
    return (
        <ProductionErrorBoundary>
            {pathname === '/signin' || pathname === '/signup' ? (
                <Layout>
                    <Component {...pageProps} />
                </Layout>
            ) : (
                <ShowMenu>
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                </ShowMenu>
            )}

            <EasterEgg iterations={2} />
            <ToastListener />
            <FixedSubgraphHealthIndicator />
            <NetworkModal pageSupportedChains={Component.chains} />
            <TransactionsDetailModal />
        </ProductionErrorBoundary>
    )
}

export default MyApp
