import { Box, BoxProps } from '@pancakeswap/uikit'
import styled from 'styled-components'

const Container: React.FC<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => (
  <CsBox {...props}>{children}</CsBox>
)

export default Container

const CsBox = styled(Box)`
  width: 100%;
  max-width: 1440px;
  padding: 24px;
  margin: 0 auto;
`
