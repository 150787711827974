import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
        <path d="M16.9498 7.05035L7.05033 16.9498" stroke="#194BFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.05029 7.05035L16.9498 16.9498" stroke="#194BFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
