import { useTranslation } from '@pancakeswap/localization'
import { Flex, LogoutIcon, PersonalIconIcon, ProfileUikitMenu, UserMenuItem } from '@pancakeswap/uikit'
import { PAGE } from 'constants/page.constants'

import { LoginContext } from 'contexts/LoginContext'
import useAuth from 'hooks/useAuth'
import { useRouter } from 'next/router'
import { useContext } from 'react'

const ProfileMenu = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { logout: logoutWallet } = useAuth()
  const { isLoggedIn, logout } = useContext(LoginContext)
  const UserMenuItems = () => {
    return (
      <>
        <UserMenuItem as="button" onClick={() => router.push(PAGE.WALLET)}>
          <Flex alignItems="center" style={{ gap: '12px' }} width="100%">
            <PersonalIconIcon />
            {t('Thông tin cá nhân')}
          </Flex>
        </UserMenuItem>
        <UserMenuItem
          as="button"
          onClick={() => {
            logoutWallet()
            logout()
          }}
        >
          <Flex alignItems="center" style={{ gap: '12px' }} width="100%">
            <LogoutIcon />
            {t('Đăng xuất')}
          </Flex>
        </UserMenuItem>
      </>
    )
  }

  if (isLoggedIn) {
    return (
      <ProfileUikitMenu text="" variant="danger" isShowIcon={!false}>
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </ProfileUikitMenu>
    )
  }
  return <></>
}

export default ProfileMenu
