import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import { jwtManager } from '../helper/jwt'
import { KEY } from '../constants/key.constants'
import { API_URL } from '../constants/api-url.constants'

// Add a request interceptor
export default function configAxios() {
    axios.defaults.baseURL = process.env.NEXT_PUBLIC_API

    axios.interceptors.request.use(
        async (config) => {
            const token = localStorage.getItem(KEY.TOKEN)
            if (token) {
                // eslint-disable-next-line no-param-reassign
                config.headers.Authorization = `Bearer ${token}`
            } else {
                jwtManager.clear()
            }
            return config
        },
        (error) => {
            Promise.reject(error)
        },
    )
    axios.interceptors.response.use(
        (response) => {
            return response
        },
        async function (error) {
            if (!navigator.onLine) {
                return Promise.reject(error)
            }

            const originalRequest = error.config
            const resetToken = localStorage.getItem(KEY.RESET_TOKEN)
            if (
                originalRequest?.url !== API_URL.REFRESH_TOKEN &&
                error &&
                error.response &&
                error?.response?.status === 401 &&
                error.response.statusText === 'Unauthorized' &&
                !originalRequest._retry
            ) {
                originalRequest._retry = true
                if (resetToken) {

                    const res = (await axios.post(API_URL.REFRESH_TOKEN, { refreshToken: resetToken })).data
                    const { success, accessToken, accessTokenExpiresIn } = res
                    if (success) {
                        localStorage.setItem(KEY.TOKEN, accessToken)
                        localStorage.setItem(KEY.TOKEN_EXPIRED_IN, accessTokenExpiresIn)
                        // eslint-disable-next-line no-param-reassign
                        axios.defaults.headers.Authorization = `Bearer ${accessToken}`
                        return axios(originalRequest)
                    }
                }
            }
            return Promise.reject(error)
        },
    )
}
