import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.input}; 
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input}; 
    border-radius: 10px;
  }

  .pagination {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #1A202C;
    list-style-type: none;
  }

  .pagination .next a:active svg, 
  .paginate .previous a:active svg {
    color: #194BFB;
  }

  .page-item {
    display: grid;
    place-content: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    font-weight: 400;
  }

  .page-item.active {
    width: 24px;
    height: 24px;
    background-color: #194BFB;
    border-radius: 100%;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    border: none;
  }

  .border-date-picker .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border-color: #e2e8f0 !important;
  }

  .border-date-picker .MuiInputBase-root input {
    padding-left: 0;

    @media screen and (max-width: 425px) {
      font-size: 12px;
    }
  }

  .border-date-picker .MuiInputBase-root {
    width: 100%;
    max-width: 250px;
    height: 48px !important;
    border-radius: 12px !important;

    position: relative;
    color: #000000;
    padding: 0 16px;
    cursor: pointer;
    box-shadow: none !important;
  }

  .border-date-picker .MuiInputBase-root:focus-visible {
    outline: none;
  }


  @media (prefers-color-scheme: dark) {
    input[type="date"]::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
    select::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }
`

export default GlobalStyle
