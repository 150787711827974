// eslint-disable-next-line import/no-cycle
import configAxios from "../apis/axiosconfig";
import {KEY} from "../constants/key.constants";

const createJwtManager = () => {
  let jwtToken = typeof window !== 'undefined' && window.localStorage ? localStorage.getItem(KEY.TOKEN) : ''
  const get = () => jwtToken
  const set = (token: string) => {
    jwtToken = token
    localStorage.setItem('token', token)
    return true
  }
  const clear = () => {
    jwtToken = null
    localStorage.removeItem(KEY.TOKEN);
    localStorage.removeItem(KEY.TOKEN_EXPIRED_IN);
    localStorage.removeItem(KEY.RESET_TOKEN);
    configAxios();
    return true
  }
  return {
    set,
    get,
    clear,
  }
}

export const jwtManager = createJwtManager()
