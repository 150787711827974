export enum API_URL {
    // auth
    REGISTER = `auth/registers`,
    LOGIN = `auth/login`,
    REFRESH_TOKEN = `auth/refresh-token`,
    FORGOT_PASSWORD_REQUEST ="/auth/forgotten-password/request",
    FORGOT_PASSWORD = "auth/forgotten-password",
    GOOGLE_LOGIN = "oauth2/google/login",
    // user
    ME = `users/me`,
    // bank
    ADD_BANK = "banks",
    GET_BANK_SUPPORT = "banks/supported",

}
