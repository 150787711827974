import { ChainId } from '@pancakeswap/sdk'

export const SUPPORT_ONLY_BSC = [ChainId.BSC]
export const SUPPORT_FARMS = [ChainId.BSC]

export const SUPPORT_ZAP = [ChainId.BSC]

export const SUPPORT_DEPAY = [
  ChainId.BSC,
  ChainId.ETHEREUM,
  ChainId.ARBITRUM,
  ChainId.ONUS,
  ChainId.ONUS,
  ChainId.OPTIMISM,
  ChainId.BSC_TESTNET,
]
